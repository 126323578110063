@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  color: white;
}

* {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
}

/* custom css */
* * {
  font-size: inherit;
}
